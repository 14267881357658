import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);


//npm start
// npm run build
//export NODE_OPTIONS=--openssl-legacy-provider