import React from 'react';
import { HomeTwo, PhoneCall, Iphone, AcceptEmail } from '@icon-park/react';
import styles from './Footer.module.css'

const Footer = () => {
    return (
        <div className={styles.footer}>
            <ul>
                <li>
                    <HomeTwo theme="outline" size="24" fill="#333" />
                    <span>2500 Esztergom, Hősök tere 1.</span>
                    </li>
                <li>
                    <PhoneCall theme="outline" size="24" fill="#333" />
                    <span>06 33 405 505</span>
                    
                </li>
                <li><Iphone theme="outline" size="24" fill="#333" />
                    <span>06 20 457 3458</span>
                    </li>
                <li>
                    <AcceptEmail theme="outline" size="24" fill="#333" />
                    <span>rendelo@dominoped.hu</span></li>
            </ul>
        </div>
    );
}

export default Footer;
