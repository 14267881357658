import React from 'react';
import styles from './Card.module.css'
import Footer from '../Layout/Footer';

const Card = (props) => {
    return (
        <>
        <div className={`${styles.container} ${styles.animatedPageLoad} ${props.className}`}>
            {props.children}
        </div>
            <Footer />
        </>
    );
}

export default Card;
