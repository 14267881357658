import React from 'react';
import style from './Hello.module.css'; 

const Hello = (props) => {
    console.log(props);
    const data = props.data[0];
    console.log(data.text)
    const formattedText = data.text.split('\n').map((p, i) => <p key={i}>{p}</p>)

    return (
        <div className={style.hello}>
            <h3>{data.title}</h3>
            <p>{formattedText}</p>
            <p className={style.signed}>{data.signed}</p>
        </div>
    );
}

export default Hello;
