import React, { useState } from 'react';
import { Link } from "react-router-dom";
import HamburgerIcon from '../Icons/HamburgerIcon';
import styles from './Navigation.module.css';
import Brand from '../../assets/logo.png';

const Navigation = () => {
    const [isNavExpanded, setIsNavExpanded] = useState(false);

    return (
        <>
        <nav className={styles.navigation}>
            <Link to="/" className={styles['brand-name']}>
                <img src={Brand} alt="DominoPed"/>
            </Link>
            <button className={styles.hamburger} onClick={() => setIsNavExpanded(!isNavExpanded)}>
                <HamburgerIcon />
            </button>
            <div
                className={isNavExpanded ? (`${styles['navigation-menu']} ${styles.expanded}`) : (styles['navigation-menu'])}>
                    <ul onClick={() => setIsNavExpanded(!isNavExpanded)}>
                    {/* <li>
                        <Link to="/login">Log in</Link>
                    </li> */}
                    <li>
                        <Link to="/rendeles">Rendelés</Link>
                    </li>
                    <li>
                        <Link to="/private">Magánrendelés</Link>
                    </li>
                    {/* <li>
                        <Link to="/covid">Covid</Link>
                    </li> */}
                    <li>
                        <Link to="/intro">Bemutatkozás</Link>
                    </li>
                    <li>
                        <Link to="/collegaues">Munkatársaim</Link>
                    </li>
                    <li>
                        <Link to="/minds">Alkalmi gondolatok</Link>
                    </li>
                    <li>
                        <Link to="/contacts">Kapcsolat</Link>
                    </li>
                </ul>
            </div>
            </nav>
        </>

    );
}

export default Navigation;



