import React from 'react';
import SubHeader from './Layout/SubHeader';
import Card from './UI/Card';
import styles from './Contact.module.css';
import { HomeTwo, PhoneCall, Iphone, AcceptEmail } from '@icon-park/react';


const Contact = (props) => {
    const map = (<iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2680.796099175614!2d18.738575815681855!3d47.78540168360229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476a6187c86772eb%3A0x3c7cd096844ae16d!2zRXN6dGVyZ29tLCBIxZFzw7ZrIHRlcmUgMSwgMjUwMA!5e0!3m2!1sen!2shu!4v1677849837433!5m2!1sen!2shu"
        title="Map"
        style={{ border: 0, height: '100%' }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade">
    </iframe>);


    return (
        <Card>
            <SubHeader title={props.title} />
            <div className={styles.infobox}>
                <div className={styles.info}>
                    <ul>
                        <li>
                            <HomeTwo theme="outline" size="24" fill="#333" />
                            <span>2500 Esztergom, Hősök tere 1.</span>
                        <hr></hr></li>
                        <li>
                            <PhoneCall theme="outline" size="24" fill="#333" />
                            <span>06 33 405 505</span>
                            <hr/>
                        </li>
                        <li><Iphone theme="outline" size="24" fill="#333" />
                            <span>06 20 457 3458</span>
                            <hr /></li>
                        <li>
                            <AcceptEmail theme="outline" size="24" fill="#333" />
                            <span>rendelo@dominoped.hu</span> <hr /></li>    
                    </ul>
                </div>
                <div className={styles.map}>
                    {map}
                </div>
            </div>
        </Card>
    );
}

export default Contact;
