import React from 'react';
import styles from './Header.module.css';
import mainImg from '../../assets/background.jpg';
import Navigation from './Navigation';
import { Outlet } from 'react-router-dom';

const Header = props => {
    const startFading = props.onScroll > 400 ? `${styles.fadeOnScroll }` : '';
    let innerHeight = window.innerHeight;
    let proportion = parseInt(100 - ((props.onScroll / innerHeight) * 100));
    let percent = `${proportion}%`;
    
    return (
        <React.Fragment>
            <header className={styles.header}>
                <Navigation />
            </header>
            <div style={{ opacity:`${percent}`}} className={`${styles['main-image']} ${startFading} `}>
                <img alt='dr. Tókos László weboldala' src={mainImg} />
            </div>
            <h1 className={styles.mainTitle}>Dr. Tókos László</h1>
            <Outlet className={styles.container} />
            
        </React.Fragment>
    );
}

export default Header;
