import React, { useState } from 'react';
import SubHeader from '../../Layout/SubHeader';
import styles from './Timetable.module.css';
import Card from '../../UI/Card';
import WinterSeason from './WinterSeason';
// import Hello from '../../UI/Hello';
import { actualtext, actualinfo, summer, nyariIdoszakok, tanacsadas } from '../../../assets/data';

const Actual = props => {
    console.log(props);
    return (
        <Card>
            <SubHeader title={props.title} />
            <h3>{actualtext[0].title}</h3>
            <p>{actualtext[0].text1}</p>
            <div>
                <table className={styles.timetable} style={{width: '50%'}}>
                <tbody>
                    {props.actualinfo.map(({ day, time, name }) => (
                        <>
                            <tr key={day}>
                                <td>{day}</td>
                                <td>{name}</td>
                                <td>{time}</td>
                            </tr>
                            <hr />
                        </>
                    ))}
                </tbody>
            </table>
            </div>
<p>{actualtext[0].text2}</p>
            <p>{actualtext[0].text3}</p>
            {/* <div style={{ display: "none" }}>
            <table className={styles.timetable}>
                <tbody>
                    {props.tanacsadas.map(({ day, time, name, phone }) => (
                        <>
                            <tr key={day}>
                                <td>{day}</td>
                                <td>{name}</td>
                                <td>{time}</td>
                                <td>{phone}</td>
                            </tr>
                            <hr />
                        </>
                    ))}
                </tbody>
                </table>
                </div> */}

             <div className={styles.date}>
                <p>{actualtext[0].date}</p>
                <p>{actualtext[0].signature}</p>
            </div>

            {/* <table className={styles.timetable}>
                <tbody>
                    {props.nyariIdoszakok.map(({ day, time, name }) => (
                        <>
                            <tr key={day}>
                                <td>{day}</td>
                                <td>{name}</td>
                            </tr>
                            <hr />
                        </>
                    ))}
                </tbody>
            </table>

            <h2>{actualtext[0].title2}</h2>
            <table className={styles.timetable}>
                <tbody>
                    {props.actualtext.map(({ name1, name2, phone1, phone2 }) => (
                        <>
                            <tr key={name1}>
                                <td>{name1}</td>
                                <td>{phone1}</td></tr>
                            <tr>
                                <td>{name2}</td>
                                <td>{phone2}</td>
                            </tr>
                            <hr />
                        </>
                    ))}
                </tbody>
            </table>

            <p>{actualtext[0].text4}</p>
            <div className={styles.date}>
                <p>{actualtext[0].date}</p>
                <p>{actualtext[0].signature}</p>
            </div> */}
        </Card>
    );
}

export default Actual;
