import React, { useState } from 'react';
import SubHeader from '../../Layout/SubHeader';
import styles from './Timetable.module.css';
import Card from '../../UI/Card';
import { emailForm } from '../../../assets/data';
import WinterSeason from './WinterSeason';
import Holiday from './Holiday';


const data = [
    {
        day: 'Hétfõ',
        time: '10.00-13.00'
    },
    {
        day: 'Kedd',
        time: '8.00-11.00'
    },
    {
        day: 'Szerda',
        time: '12.30-15.30'
    },
    {
        day: 'Csütörtök',
        time: '7.00-10.00'
    },
    {
        day: 'Péntek',
        time: '12.30-15.30'
    }
]

const form1 = (
    <div >
        <p>Név: ……………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………</p>
        <p>Szül.dátum …………………………………………………………………………………………………………………………………………………………………</p>
        <p>Szülő telefonszáma ……………………………………………………………………………………………………………………………………………………</p>
        <p>Tünetek ………………………………………………………………………………………………………………………………………………………………………</p>
        <p>Adott kezelés ………………………………………………………………………………………………………………………………………………………………</p>
        <p>Kérés, kérdés / fénykép küldése, táppénz, stb / …………………………………………………………………………………………………………</p>
    </div>
)

const form2 = (
    <div>
        <p>Név: ……………………………………………………………………………………………………………………………………………………</p>
        <p>Tünetek megszűntek-e?……………………………………………………………………………………………………………………</p>
        <p>Igazolás időtartama dátum szerint………………………………………………………………………………………………</p>
        <p>Iskola, óvoda megnevezése…………………………………………………………………………………………………………………………</p>
        <p>Osztály vagy ovis csoport megjelölése…………………………………………………………………………………………………………</p>
    </div>
)

const Timetable = (props) => {
    const [timeTableData, setTimeTableData] = useState(data);

    // const onChange = (e) => {
    //     console.log('onchange');
    //     const { day, time } = e.target

    //     // const editData = timeTableData.map((item) =>
    //     //     item.day === day && time ? { ...item, [time]: value } : item
    //     // )

    //     // setTimeTableData(editData)
    // }


    return (
        <Card>
            {/* <WinterSeason/> */}
            {/* <Holiday /> */}
            <div className={styles.rendeles}>
                <SubHeader title="Rendelői Rend" />
                <div className={styles.rendelesiRend}>
                <h3><b>{emailForm[0].title }</b></h3>
                <div>{emailForm[0].text1}</div>
                <div>{emailForm[0].text2}</div>
                <div className={styles.form}>{form1}</div>
                <div>{emailForm[0].text3 }</div>
                <div className={styles.form}>{form2}</div>
                <div>{ emailForm[0].text4}</div>
                    <div>{emailForm[0].dateTime}</div>
                </div>
            </div>
            <div className="rendeles">
                <SubHeader title="Rendelés" />
                <table className={styles.timetable}>
                    <tbody>
                        {timeTableData.map(({ day, time }) => (
                            <>
                            <tr key={day}>
                                <td>
                                    {/* <input
                                        name="day"
                                        value={day}
                                        type="text"
                                        onChange={(e) => onChange(e, day)}
                                    /> */}
                                        {day}
                                </td>
                                <td>
                                    {/* <input
                                        name="time"
                                        value={time}
                                        type="text"
                                        onChange={(e) => onChange(e, day)}
                                    /> */}
                                        {time}
                                    </td>
                                
                            </tr>
                                <hr />
                            </>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="tanacsadas">
                <SubHeader title="Tanácsadas"/>
                <table className={styles.timetable}>
                        <tbody>
                                <tr >
                                <td>Csütörtök</td>
                                <td>10.00-12.30</td>
                                <td>	dr. Tókos László pácienseinek</td>
                                </tr>
                        </tbody>
                    </table>
            </div>
            <div className="erdeklodes">
                    <SubHeader title="ÉRDEKLŐDNI AZ ALÁBBI TELEFON SZÁMOKON" />
                    <table className={styles.timetable}>
                        <tbody>
                                <tr >
                                <td>dr. Tókos László</td>
                                    <td></td>
                                <td>06-33-405-505</td>
                                </tr>
                        </tbody>
                    </table>
            </div>
            </Card>
    );
}
export default Timetable;