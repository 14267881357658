import React from 'react';
import Card from './UI/Card';
import styles from '../components/PersonalInfo.module.css';
import SubHeader from './Layout/SubHeader';
import Image from './UI/Image';

const Collegaue = (props) => {
    const { title, data } = props;

    const content = data.map(x => {
        const block1 = x.text1.split('\n');
        return (
            <div className={styles.shortIntro}>
                <Image className={styles.image} alt={x.name} src={x.url1} width='40%' />
                <h2>{x.name}</h2>
                {block1.map(text => <p>{text}</p>)}
            </div>
        )
    });

    return (
        <Card>
            <SubHeader title={title} />
            <div>{content}
                {/* <div className={styles.shortIntro}>
                    <Image className={styles.image} alt={data[0].name} src={data[0].url1} width='50%' />
                    <h2>{data.name}</h2>
                    {block1.map(x => <p>{x}</p>)}
                </div>
                {block2.map(x => <p>{x}</p>)}
                <Image className={title} alt={data[0].name} src={data[0].url2} width='100%' /> */}
            </div>
        </Card>
    );
}

export default Collegaue;
