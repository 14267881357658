import React, { useState } from 'react';
import SubHeader from '../../Layout/SubHeader';
import styles from './Timetable.module.css';
import style2 from './Private.module.css';
import Card from '../../UI/Card';


const rendelesiIdo = [
    {
        day: 'Hétfõ',
        time: '18.00-19.00'
    },
    {
        day: 'Kedd',
        time: '12.00-13.00'
    },
    {
        day: 'Szerda',
        time: '16.00-17.00'
    },
    {
        day: 'Csütörtök',
        time: '13.00-14.00'
    },
    {
        day: 'Péntek',
        time: '16.00-17.00'
    },
    {
        day: 'Szombat',
        time: '8.00-9.00'
    }
]

const egyebinfok = [
    {
        key: 'Szolgáltatások:',
        value:'betegrendelés, csecsemő tanácsadás (keddenként), homeopátiás szaktanácsadás'
    },
    {
        key: 'Bejelentkezés:',
        value: '06 33 405 505, 06 20 457 3458'
    },
    {
        key: 'Magánrendelés díja:',
        value: '7000 Ft'
    },
    {
        key: 'Csecsemő tanácsadás díja:',
        value: '4000 Ft'
    },
    {
        key: 'Egészségpénztári kártyák:',
        value: 'elfogadása'
    },
]

const Private = (props) => {
    const [privateTableData, setPrivateTableData] = useState(rendelesiIdo);
    const [isVisible, setIsVisible] = useState(true);

    return (
        <Card>
            <div className="rendeles">
                <SubHeader title="Magánrendelés" />
                <div className={style2.toggleButtons}>
                    <button className={isVisible ? `${style2.colored}`: ''} type="text" onClick={() => setIsVisible(true)}>Rendelési idõ</button>
                    <button className={!isVisible ? `${style2.colored}` : ''} type="text" onClick={() => setIsVisible(false)}>Árak, egyéb információk</button>
                </div>
                {isVisible ? (<table className={styles.timetable}>
                    <tbody>
                        {privateTableData.map(({ day, time }) => (
                            <>
                                <tr key={day}>
                                    <td>{day}</td>
                                    <td>{time}</td>
                                </tr>
                                <hr />
                            </>
                        ))}
                    </tbody>
                </table>) : (
                    <table className={style2.privatetable}>
                        <tbody className={style2.egyebinfok}>
                                {egyebinfok.map(({ key, value }) => {
                                    const list = value.split(',');
                                    console.log(list);
                                    return (<>
                                        <tr key={key}>
                                            <td>{key}</td>
                                            <td>
                                                <ul>
                                                    {list.map((element) => <li key={Math.random()}>{element}</li>)}
                                                </ul>
                                            </td>
                                        </tr>
                                        <hr />
                                    </>)
                                })}
                        </tbody>
                    </table>
                )}
            </div>     
        </Card>
    );
}
export default Private;