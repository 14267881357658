import React from 'react';

const Image = (props) => {

    return (
        <img className={props.className} alt={props.alt} src={props.src} width={props.width} />           
    );
}

export default Image;
