import React, {useState} from 'react';
import Card from './UI/Card';
import Button from './UI/Button';
import SubHeader from './Layout/SubHeader';
import styles from './Mind.module.css';
import { hello } from '../assets/data';
import Hello from './Hello';

const Mind = (props) => {
    const [isVisible, setIsVisible] = useState([false, false, false]);
    const handleShowMind = e => {
        e.preventDefault();
        isVisible[e.target.id] = !isVisible[e.target.id];
        setIsVisible((prev) => [...isVisible]);


    }
    const mindlist = props.minds.map((m, i) => {
        const formattedText = m.text.split('\n').map(text => <p key={Math.random()}>{text}</p>)
     
        return (
            <div className={styles.mind} key={i}>
                <div className={styles.headlines}>
                    <p>{m.date}</p>
                    <p className={styles.headtitle}>{m.headtitle}</p>
                    <Button id={i} onClick={handleShowMind} >...                        
                    </Button>
                </div>

                {isVisible[i] && <p className={styles.text}>{formattedText}</p>}
                <hr />
            </div>
        )
    })
    return (
        <Card>
            <SubHeader title={props.title} />
            <Hello data={hello} />
            <hr/>
            {mindlist}
        </Card>
    );
}

export default Mind;
